<template>
  <section class="projects fade d-flex flex-column">
    <div class="edit-project-wrapper">
      <div class="edit-project__header mb-6">
        <div class="edit-project__title">Редактировать проект</div>
        <v-btn class="edit-project__close" icon plain @click="closeEdit">
          <v-icon color="black" size="20">mdi-close</v-icon>
        </v-btn>
      </div>
      <div class="project-info">
        <v-form ref="form" v-model="valid" class="col-md-10 col-xl-8 mt-8" lazy-validation>
          <div class="d-flex justify-space-between mb-4">
            <div class="col-item">
              <v-text-field
                v-model="project.title"
                label="Название проекта"
                class="rounded-lg"
                required
                :rules="nameRules"
                hide-details
                background-color="white"
                outlined />
            </div>

            <div class="col-item">
              <v-select
                :items="getFilterPms"
                v-model="project.manager"
                item-text="value"
                item-value="id"
                label="Менеджер"
                required
                :rules="nameRules"
                hide-details
                outlined
                background-color="white"
                class="rounded-lg" />
            </div>
            <div class="col-item">
              <v-text-field
                v-model="project.code"
                label="Ключ проекта в Jira"
                class="rounded-lg"
                hide-details
                background-color="white"
                outlined
                @input="onInputProjectCode"
                :error="projectCodeIsError" />
            </div>
          </div>
          <div class="d-flex justify-space-between mb-4">
            <div class="col-item">
              <DatePicker
                placeholder="Начало проекта"
                :value="project.date_start"
                @changeDate="changeDateFirst"
              />
            </div>
            <div class="col-item">
              <DatePicker
                placeholder="Окончание проекта"
                :value="project.date_end"
                :isRequired="false"
                @changeDate="changeDateSecond"
              />
            </div>

            <div class="col-item">
              <v-select
                :items="status"
                v-model="project.status"
                label="Статус"
                outlined
                hide-details
                required
                :rules="nameRules"
                background-color="white"
                class="rounded-lg" />
            </div>
          </div>

          <div class="d-flex justify-space-between">
            <v-textarea
              outlined
              v-model="project.description"
              name="input-7-4"
              hide-details
              class="rounded-lg"
              background-color="white"
              no-resize
              height="135"
              label="Описание"
              required
              :rules="nameRules" />
          </div>
        </v-form>
      </div>
    </div>

    <v-form v-model="validSpec" ref="formSpec" class="mt-8" lazy-validation>
      <div class="specialist-rate">
        <div class="specialist-rate__resource_type mb-4">Ставки специалистов</div>
        <div class="specialist-rate__content d-flex flex-column justify-space-between">
          <div class="specialist-rate__inputs">
            <v-col
              cols="auto"
              v-for="(specialist, index) in project.specialists"
              :key="index"
            >
              <v-row class="d-flex align-center">
                <v-select
                  class="position rounded-lg mr-4"
                  outlined
                  label="Должность"
                  item-text="value"
                  item-value="id"
                  :items="getSpecialists(specialist.resource_kind.id)"
                  hide-details
                  v-model="specialist.resource_kind.id"
                  :rules="nameRules"
                  required
                ></v-select>

                <PayTime
                  @changePayTime="changePayTime"
                  :value="String(specialist.payment)"
                  :index="index"
                />

                <v-btn
                  v-if="project.specialists.length !== 1"
                  icon
                  class="ml-4"
                  @click="deleteRate(index)"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </v-row>
            </v-col>
          </div>
          <v-btn
            outlined
            class="btn-add radius-root mt-3"
            height="52"
            width="234"
            @click="addRate"
          >+ Добавить сотрудника</v-btn>
        </div>
      </div>

    </v-form>

    <v-btn
      class="white--text radius-root btn-create mx-auto"
      width="210"
      color="black"
      height="52"
      :disabled="!valid || !validSpec"
      @click="submit()">
      Сохранить
    </v-btn>
  </section>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import debounce from 'lodash/debounce';
import { statuses } from '@/core/constants/statuses';
import notification from '@/mixins/notification';
import PayTime from '@/components/Inputs/PayTime.vue';
import DatePicker from '@/components/UI/DatePicker.vue';

export default {
  name: 'EditProject',
  mixins: [notification],
  components: {
    PayTime,
    DatePicker,
  },
  props: {
    projectDetail: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      valid: false,
      validSpec: false,
      project: {
        title: '',
        description: '',
        status: '',
        manager: '',
        code: '',
        budget: '',
        date_start: '',
        date_end: '',
        specialists: [],
      },
      status: statuses,

      projectCodeIsError: false,
    };
  },
  computed: {
    ...mapState(['filters']),
    getFilterPms() {
      return this.filters?.pms || [];
    },
  },
  methods: {
    ...mapActions('projects', ['editProject', 'getProject', 'getProjectFromJIRA']),
    ...mapActions(['getFilters']),

    getSpecialists(id) {
      return this.filters.positions.filter((spec) => !this.project
        .specialists.some((proj) => proj.resource_kind.id === spec.id && proj.resource_kind.id !== id));
    },

    changeDateFirst(val) {
      this.project.date_start = val;
    },

    changeDateSecond(val) {
      this.project.date_end = val;
    },

    changeBudget(val) {
      this.project.budget = val;
    },

    changePayTime(val) {
      this.project.specialists[val.index].payment = val.payment;
    },
    onInputProjectCode() {
      if (this.project.code) {
        this.projectCodeIsError = true;
        this.debounceFetchProjectFromJIRA();
      } else {
        this.projectCodeIsError = false;
      }
    },
    fetchProjectFromJIRA() {
      if (!this.project.code) {
        this.projectCodeIsError = false;
        return;
      }
      this.getProjectFromJIRA(this.project.code)
        .then(({ data }) => {
          this.project.image_url = data.avatar;
          this.project.title = data.name;
          // if (data.manager.user_id) this.project.manager = data.manager.user_id;
          this.projectCodeIsError = false;
        })
        .catch(() => {
          this.projectCodeIsError = true;
        });
    },

    debounceFetchProjectFromJIRA: debounce(function () {
      this.fetchProjectFromJIRA();
    }, 300),

    closeEdit() {
      if (this.$route.name === 'EditProject') {
        this.$router.back();
      } else {
        this.$emit('closeEdit');
      }
    },
    // eslint-disable-next-line consistent-return
    checkValid() {
      this.$refs.form.validate();
      this.$refs.formSpec.validate();

      return new Promise((resolve) => {
        this.isDatesPeriodValid(this.project.date_start, this.project.date_end, () => {
          this.project.date_end = '';
          this.showErrorNotification('Дата окончания проекта введена некорректно');
          resolve(false);
        }, () => {
          resolve(!!(this.$refs.form.validate() && this.$refs.formSpec.validate()));
        });
      });
    },
    async submit() {
      await this.fetchProjectFromJIRA();
      if (await this.checkValid()) {
        try {
          const res = await this.editProject({
            ...this.project,
            specialists: this.project.specialists
              .filter((el) => el.payment && el.resource_kind)
              .map((el) => ({
                id: el.id,
                payment: el.payment,
                resource_kind: el.resource_kind?.id,
              })),
            manager: this.project.manager,
          });
          if (res.error) {
            this.showErrorNotification('Ошибка при редактировании проекта');
          } else {
            this.$router.push(`/projects/${this.project.id}`);
          }
        } catch (error) {
          return error;
        }
      }
    },
    addRate() {
      this.project.specialists.push({ resource_kind: { id: null }, payment: '' });
    },
    deleteRate(index) {
      this.project.specialists.splice(index, 1);
    },
  },
  async created() {
    await this.getFilters();

    if (!this.projectDetail) {
      this.project = await this.getProject(this.$route.params.id);
      this.dates = [this.project.date_start, this.project.date_end];
    } else {
      this.project = JSON.parse(JSON.stringify(this.projectDetail));
      this.dates = [this.projectDetail.date_start, this.projectDetail.date_end];
    }
  },
};
</script>
<style lang="scss" scoped>
::v-deep .v-text-field fieldset {
  color: #d6d6d6 !important;
}

.btn-edit {
  color: white;
  align-self: center;
  &:before {
    background-color: #2d2d2d !important;
    opacity: 1 !important;
  }
}

.edit-project-wrapper {
  margin-bottom: 40px;
}

.edit-project__header {
  display: flex;
  align-items: center;
}

.edit-project__title {
  margin-left: 32px;
  font-weight: bold;
  font-size: 28px;
  line-height: 34px;
}

.edit-project__close {
  margin-left: auto;
  margin-right: 10px;
}

.project-info {
  border: 1px solid #f1f1f1;
  border-radius: 32px;
  height: 359px;
  background: #f9f9f9;
  & > * {
    padding: 0 32px;
  }
}

.col-item {
  display: flex;
  width: 33%;
}

.modal__sub-title {
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 24px;
}

.staff-list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.staff-list__item {
  width: 49%;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  & .v-input {
    max-width: 190px;
  }
}
.specialist-rate {
  padding: 0 32px;
  .v-select {
    width: 220px;
    max-width: 220px;
  }
}

.specialist-rate__inputs {
  display: flex;
  flex-wrap: wrap;

  & > * {
    margin: 0 30px 20px 0px;
  }
}
</style>
