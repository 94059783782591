<template>
  <v-text-field
    :value="getValue"
    @keydown="keydownValue($event)"
    @input="inputValue($event)"
    required
    :label="label"
    :placeholder="placeholder"
    class="rounded-lg"
    hide-details
    background-color="white"
    outlined
    maxlength="7"
    :rules="nameRules"
  ></v-text-field>
</template>

<script>
export default {
  name: 'PayTime',
  props: {
    value: {
      type: String,
      default: '',
    },
    index: {
      type: Number,
      default: 0,
    },
    label: {
      type: String,
      default: 'Зп/час, руб',
    },
    placeholder: {
      type: String,
      default: '2000',
    },
  },
  data() {
    return {
      valueMain: '',
    };
  },

  mounted() {
    this.valueMain = this.value;
  },

  watch: {
    value(val) {
      this.valueMain = val;
    },
  },

  computed: {
    getValue() {
      return this.valueMain;
    },
  },

  methods: {
    keydownValue(e) {
      const reg = /\d/;
      if (!reg.test(e.key)) {
        if (e.key !== 'Backspace' && e.key !== 'Delete' && e.key !== 'ArrowLeft' && e.key !== 'ArrowRight') {
          e.preventDefault();
        }
      }// else if (e.key === '0' && this.valueMain.length === 0) e.preventDefault()
    },
    inputValue(e) {
      const str = e.replace(/\s+/g, '');
      this.valueMain = str;
      this.$emit('changePayTime', {
        payment: this.valueMain.replace(/\s+/g, ''),
        index: this.index,
      });
    },
  },
};

</script>
